@import "../colors/colors.module";
@import "../mixins";

.drift-widget-wrapper {
  position: fixed;
  z-index: 10;
  right: 2rem;
  bottom: 4.5rem;

  @include media-breakpoint-up(lg) {
    bottom: 2rem;
  }

  &.drift-frame-controller-hide {
    display: none;
  }
}

.drift-frame-controller-move-for-google-maps {
  right: 53px !important;
}

.drift-frame-controller-hide {
  opacity: 0 !important;
  z-index: -100 !important;
  height: 0 !important;
  width: 0 !important;
}

#drift-frame-chat {
  bottom: 120px !important;

  @include media-breakpoint-up(lg) {
    bottom: 88px !important;
  }
}

#drift-frame-controller {
  bottom: 3.5rem !important;

  @include media-breakpoint-up(lg) {
    bottom: 24px !important;
  }
}
